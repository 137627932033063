import { Inject, Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { isDefined } from '@sites/dashboard/util';
import { ObservableClient } from '@sites/data-connect';
import { FilesService } from '@sites/data-hmm/hmm-files';
import { CreativesService } from '@sites/data-hmm/hmm-incubator';
import {
  EMPTY,
  asyncScheduler,
  bufferTime,
  catchError,
  filter,
  map,
  mergeMap,
  observeOn,
  of,
} from 'rxjs';
import { enterZone, leaveZone } from '../util/zone-scheduler.util';
import { creativeServiceAction } from './creative.actions';
import {
  selectCreativeById,
  selectCreativeEntities,
  selectCreativeWithResourcesById,
  selectCreativesByIds,
  selectCreativesWithResourcesByIds,
} from './creative.selectors';

// 59 minutes
const PUBLIC_URL_EXPIRY_TIME = 3540000;

@Injectable()
export class CreativeEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(CreativesService)
    private creativesService: ObservableClient<typeof CreativesService>,
    @Inject(FilesService)
    private filesService: ObservableClient<typeof FilesService>,
    private zone: NgZone
  ) {}

  getCreative$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(creativeServiceAction.get),
      // Buffer time renders the application unstable
      // So we run the scheduler outside of the zone
      // Buffered over 50ms but maxes at 10 items
      bufferTime(50, null, 20, leaveZone(this.zone, asyncScheduler)),
      // Which returns when the following filter is true
      filter((values) => values.length !== 0),
      observeOn(enterZone(this.zone, asyncScheduler)),
      concatLatestFrom((creativeIds) =>
        this.store.select(
          selectCreativesByIds(creativeIds.map(({ creativeId }) => creativeId))
        )
      ),
      mergeMap(([creativeIds, creatives]) => {
        const ids = creativeIds
          .map(({ creativeId }) => creativeId)
          .filter((id) => !creatives.find((creative) => creative?.id === id));

        if (ids.length === 0) {
          return EMPTY;
        }
        return this.creativesService.listCreatives({ creativeIds: ids }).pipe(
          map(({ results }) => {
            return creativeServiceAction.listSuccess({
              creatives: results,
            });
          })
        );
      }),
      catchError((error) => of(creativeServiceAction.listFailure({ error })))
    );
  });

  getCreatives$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(creativeServiceAction.list),
      mergeMap(({ creativeIds }) => {
        return this.creativesService.listCreatives({ creativeIds }).pipe(
          map(({ results }) => {
            return creativeServiceAction.listSuccess({
              creatives: results,
            });
          })
        );
      }),
      catchError((error) => of(creativeServiceAction.listFailure({ error })))
    );
  });

  refreshThumbnail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(creativeServiceAction.get),
      // Buffer time renders the application unstable
      // So we run the scheduler outside of the zone
      bufferTime(50, null, 20, leaveZone(this.zone, asyncScheduler)),
      // Which returns when the following filter is true
      filter((values) => values.length !== 0),
      observeOn(enterZone(this.zone, asyncScheduler)),
      concatLatestFrom((creativeIds) =>
        this.store.select(
          selectCreativesWithResourcesByIds(
            creativeIds.map(({ creativeId }) => creativeId)
          )
        )
      ),
      map(([, storeCreatives]) => {
        const creativesToRefresh = storeCreatives
          .filter(isDefined)
          .filter(
            (c) =>
              c &&
              c.thumbnailUrl &&
              c.thumbnailLoadedTime &&
              new Date().getTime() - c.thumbnailLoadedTime >
                PUBLIC_URL_EXPIRY_TIME
          );

        return creativeServiceAction.refreshThumbnails({
          creatives: creativesToRefresh.map((c) => c.creative),
        });
      })
    );
  });

  loadThumbnails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        creativeServiceAction.listSuccess,
        creativeServiceAction.refreshThumbnails
      ),
      map((values) => values.creatives),
      filter((creatives) => creatives.filter((x) => x.thumbnail).length !== 0),
      mergeMap((creatives) => {
        return this.filesService
          .listPublicUrls({
            items: creatives.map((creative) => ({
              key: creative.thumbnail,
            })),
          })
          .pipe(
            map(({ results }) =>
              creativeServiceAction.loadThumbnailsSuccess({
                thumbnails: creatives.map((creative) => {
                  return {
                    creativeId: creative.id,
                    thumbnailUrl:
                      results.find((item) => item.key === creative.thumbnail)
                        ?.url || '',
                  };
                }),
              })
            ),
            catchError((error) =>
              of(creativeServiceAction.loadThumbnailsFailure({ error }))
            )
          );
      })
    );
  });

  loadGif$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(creativeServiceAction.loadGif),
      concatLatestFrom(({ creativeId }) =>
        this.store.select(selectCreativeById(creativeId))
      ),
      concatLatestFrom(() => this.store.select(selectCreativeEntities)),
      filter(([[, creative], entities]) => {
        // Filter out undefined creatives
        if (!creative) {
          return false;
        }

        // Filter out creatives that have already been cached
        const creativeWithResources = entities[creative.id];
        return !(
          creativeWithResources &&
          creativeWithResources.gifUrl &&
          creativeWithResources.gifLoadedTime &&
          new Date().getTime() - creativeWithResources.gifLoadedTime <
            PUBLIC_URL_EXPIRY_TIME
        );
      }),
      map(([[, creative]]) => creative),
      filter(isDefined),
      mergeMap((creative) => {
        return this.filesService
          .getPublicUrl({
            key: creative.gif,
          })
          .pipe(
            map(({ url }) =>
              creativeServiceAction.loadGifSuccess({
                creativeId: creative.id,
                gifUrl: url,
              })
            ),
            catchError((error) =>
              of(creativeServiceAction.loadGifFailure({ error }))
            )
          );
      })
    );
  });

  loadRecording$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(creativeServiceAction.loadRecording),
      concatLatestFrom(({ creativeId }) =>
        this.store.select(selectCreativeById(creativeId))
      ),
      map(([, creative]) => creative),
      filter(isDefined),
      concatLatestFrom((creative) =>
        this.store.select(selectCreativeWithResourcesById(creative.id))
      ),
      mergeMap(([creative, storeCreative]) => {
        if (
          storeCreative &&
          storeCreative.recordingUrl &&
          storeCreative.recordingLoadedTime &&
          new Date().getTime() - storeCreative.recordingLoadedTime <
            PUBLIC_URL_EXPIRY_TIME
        ) {
          return EMPTY;
        }
        return this.filesService.getPublicUrl({ key: creative.recording }).pipe(
          map(({ url }) =>
            creativeServiceAction.loadRecordingSuccess({
              creativeId: creative.id,
              recordingUrl: url,
            })
          ),
          catchError((error) =>
            of(creativeServiceAction.loadRecordingFailure({ error }))
          )
        );
      })
    );
  });
}
