@if (navigationService.routeLoading$ | async) {
  <div
    class="tw-fixed tw-top-0 tw-z-30 tw-h-0.5 tw-w-full tw-animate-pulse tw-bg-leaf tw-shadow tw-shadow-leaf"
  ></div>
}

@if (navigationService.routeData$ | async; as routeData) {
  <main
    class="tw-flex tw-flex-col"
    [class.tw-h-screen]="routeData?.['fullScreenHeight']"
  >
    <div
      class="tw-flex tw-h-16 tw-shrink-0 tw-select-none tw-items-center tw-justify-between tw-border-b tw-border-night-2 tw-bg-night tw-px-4 lg:tw-h-20 lg:tw-px-6 2xl:tw-px-8"
    >
      <a
        [routerLink]="['/']"
        class="tw-relative tw-inline-flex tw-bg-night tw-text-night tw-no-underline"
        title="Dashboard - Human Made Machine"
      >
        <img
          src="https://assets.humanmademachine.com/hmm/logos/white/brand/HMM_Marque_White.svg"
          class="tw-h-[35px] lg:tw-hidden"
          alt="Human Made Machine"
        />
        <img
          src="https://assets.humanmademachine.com/hmm/logos/white/brand/HMM_Word_Marque_White.svg"
          class="tw-hidden tw-h-[40px] lg:tw-inline"
          alt="Human Made Machine"
        />
        <span class="visually-hidden">Dashboard - Human Made Machine</span>
        @if (!isProduction) {
          <span
            [dashboardUiTooltip]="environment"
            class="position-absolute start-90 translate-middle bg-danger rounded-circle top-10 tw-p-1.5"
            [ngClass]="{ 'bg-info': isLocal, 'bg-danger': !isLocal }"
          >
            <span class="visually-hidden">{{ environment }}</span>
          </span>
        }
      </a>
      <button
        class="tw-btn tw-btn-dark tw-p-2 tw-text-2xl tw-leading-[0] tw-text-white"
        (click)="menuService.toggle()"
        role="button"
        title="Toggle Menu"
      >
        <i class="bi bi-list"></i>
      </button>
    </div>
    <router-outlet></router-outlet>
  </main>
}
