import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  Event,
  EventType,
  Router,
  RouterModule,
  Scroll,
} from '@angular/router';
import { Code, ConnectError } from '@connectrpc/connect';
import { AuthService } from '@sites/data-auth';
import { NavigationService } from '@sites/util-navigation';
import { filter, pairwise } from 'rxjs';

@Component({
  selector: 'dashboard-root',
  templateUrl: './app.component.html',
  imports: [RouterModule],
})
export class AppComponent {
  constructor(
    @Inject(DOCUMENT) document: Document,
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService,
    private viewportScroller: ViewportScroller
  ) {
    this.authService.user$.subscribe((user) => {
      if (document.defaultView) {
        // @ts-expect-error gtag is available on the window
        document.defaultView.gtag('set', 'user_properties', {
          org: user.org,
        });
        // @ts-expect-error gtag is available on the window
        document.defaultView.gtag('set', 'user_id', user.email);
      }
    });

    // Handle navigation errors, if resolvers fail navigation isn't
    // completed and links will look broken. Instead handle the error
    // and redirect to an appropriate error page.
    this.navigationService.routeErrored$.subscribe((event) => {
      if (event.error instanceof ConnectError) {
        switch (event.error.code) {
          case Code.Unknown:
            // Not an error, do nothing
            break;
          case Code.Unauthenticated:
            // Handled be error interceptor
            break;
          case Code.PermissionDenied:
            this.router.navigate(['error', 'denied']);
            break;
          default:
            this.router.navigate(['error']);
            break;
        }
      }
    });

    // Handle scroll restoration
    // This custom handler stops query param changes from scrolling to top
    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        pairwise()
      )
      .subscribe((eventPair) => {
        const previousEvent = eventPair[0];
        const event = eventPair[1];
        if (event.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          if (
            previousEvent.routerEvent.type === EventType.NavigationEnd &&
            event.routerEvent.type === EventType.NavigationEnd &&
            previousEvent.routerEvent.urlAfterRedirects.split('?')[0] !==
              event.routerEvent.urlAfterRedirects.split('?')[0]
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}
