import {
  BaseRouterStoreState,
  RouterReducerState,
  routerReducer,
} from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { provideClient } from '@sites/data-connect';
import { AuthzService } from '@sites/data-hmm/hmm-authz';
import { FilesService } from '@sites/data-hmm/hmm-files';
import {
  CreativesService,
  PropertiesService,
  ResultsGenerationService,
  StudyService,
} from '@sites/data-hmm/hmm-incubator';
import { JobService } from '@sites/data-hmm/hmm-job';
import {
  AudienceService,
  PanelsService,
  SurveysService,
} from '@sites/data-hmm/hmm-ninja';
import { OrgService } from '@sites/data-hmm/hmm-orgs';
import { FinanceService, ProjectService } from '@sites/data-hmm/hmm-projects';
import { AudienceTargetDetailEffects } from '../audience-target-detail/audience-target-detail.effects';
import {
  AudienceTargetDetailEntityState,
  AudienceTargetDetailKey,
  audienceTargetDetailReducer,
} from '../audience-target-detail/audience-target-detail.reducer';
import { AuthEffects } from '../auth/auth.effects';
import { AuthEntityState, authReducer } from '../auth/auth.reducer';
import { BrandEffects } from '../brand/brand.effects';
import {
  BrandEntityState,
  BrandKey,
  brandReducer,
} from '../brand/brand.reducer';
import { CreativeEffects } from '../creative/creative.effects';
import {
  CreativeEntityState,
  creativeReducer,
} from '../creative/creative.reducer';
import { FinanceEntityEffects } from '../finance-entity/finance-entity.effects';
import {
  FinanceEntityKey,
  FinanceEntityState,
  financeEntityReducer,
} from '../finance-entity/finance-entity.reducer';
import { OrgEffects } from '../org/org.effects';
import { OrgEntityState, OrgKey, orgReducer } from '../org/org.reducer';
import { PanelEffects } from '../panel/panel.effects';
import {
  PanelEntityState,
  PanelKey,
  panelReducer,
} from '../panel/panel.reducer';
import { PropertyEffects } from '../property/property.effects';
import {
  PropertyKey,
  PropertyState,
  propertyReducer,
} from '../property/property.reducer';
import { ServiceWorkerEffects } from '../service-worker/service-worker.effects';
import {
  ServiceWorkerKey,
  ServiceWorkerState,
  serviceWorkerReducer,
} from '../service-worker/service-worker.reducer';
import { UserEffects } from '../user/user.effects';
import { UserEntityState, UserKey, userReducer } from '../user/user.reducer';

export interface RootState {
  router: RouterReducerState<BaseRouterStoreState>;
  auth: AuthEntityState;
  creative: CreativeEntityState;

  [BrandKey]: BrandEntityState;
  [FinanceEntityKey]: FinanceEntityState;
  [OrgKey]: OrgEntityState;
  [PanelKey]: PanelEntityState;
  [PropertyKey]: PropertyState;
  [UserKey]: UserEntityState;
  [ServiceWorkerKey]: ServiceWorkerState;
  [AudienceTargetDetailKey]: AudienceTargetDetailEntityState;
}

export const selectRootState = (state: RootState) => state;

export const reducers: ActionReducerMap<RootState> = {
  router: routerReducer,
  auth: authReducer,
  creative: creativeReducer,

  [AudienceTargetDetailKey]: audienceTargetDetailReducer,
  [BrandKey]: brandReducer,
  [FinanceEntityKey]: financeEntityReducer,
  [OrgKey]: orgReducer,
  [PanelKey]: panelReducer,
  [PropertyKey]: propertyReducer,
  [UserKey]: userReducer,
  [ServiceWorkerKey]: serviceWorkerReducer,
};

export const effects = [
  AuthEffects,
  CreativeEffects,
  BrandEffects,
  FinanceEntityEffects,
  OrgEffects,
  PanelEffects,
  PropertyEffects,
  UserEffects,
  ServiceWorkerEffects,
  AudienceTargetDetailEffects,
];

export const ROOT_STORE_PROVIDERS = [
  provideClient(OrgService),
  provideClient(AuthzService),
  provideClient(ProjectService),
  provideClient(StudyService),
  provideClient(PropertiesService),
  provideClient(FinanceService),
  provideClient(FilesService),
  provideClient(JobService),
  provideClient(SurveysService),
  provideClient(CreativesService),
  provideClient(PanelsService),
  provideClient(AudienceService),
  provideClient(ResultsGenerationService),
];
