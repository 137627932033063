import { EnvironmentEnum } from '@sites/util-environment';

export const environment = {
  name: EnvironmentEnum.Production,
  production: true,
  // Replaced by actual value during build
  release: 'f6f2883330664534d31cfc70b27a0323f169cb44',

  aeUrl: 'https://ae.humanmademachine.com',
  propertyUrl: 'https://i.srvy.ninja',
  distributionLinkUrl: 'https://dist.srvy.ninja',
  srvyNinjaUrl: 'https://srvy.ninja',

  apiUrl: 'https://api.humanmademachine.com',

  authAuthority: 'https://auth.humanmademachine.com',
  authClientId: 'hmm-web-spa',
  authRedirectUrl: window.location.origin,
  authPostLogoutRedirectUrl: window.location.origin,
  authUnauthorizedRoute: '/error/unauthorized',

  sentryDsn:
    'https://c101ebb76bd44a9ea420f31a80dccf55@o98546.ingest.sentry.io/6250832',
  sentrySampleRate: 1.0,

  appImports: [],
};
