import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TooltipDirective } from '@sites/dashboard/ui';
import { EnvironmentEnum } from '@sites/util-environment';
import { NavigationService } from '@sites/util-navigation';
import { environment } from '../../environments/environment';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'dashboard-default-layout',
  templateUrl: './default-layout.component.html',
  imports: [CommonModule, RouterModule, TooltipDirective],
})
export class DefaultLayoutComponent {
  isProduction = environment.name === EnvironmentEnum.Production;
  isLocal = environment.name === EnvironmentEnum.Local;
  environment = environment.name;

  navigationService = inject(NavigationService);
  menuService = inject(MenuService);
  document = inject(DOCUMENT);
}
